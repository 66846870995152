@media screen and (max-width: 600px) {
    .column {
        /*float: left;*/
        width: 50%;
        /*margin-right: 50px;*/
    }
}
/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}
.social-container {
    background: #eee;
    padding: 25px 50px;
    position: fixed;
    bottom: 0;
    width: 100%;
}
a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}
a.social:hover {
    transform: translateY(-2px);
}

a.youtube {
    color: #eb3223;
}

a.facebook {
    color: #4968ad;
}

a.twitter {
    color: #49a1eb;
}

a.instagram {
    color: black;
}

.new-line {
    white-space: pre-line;
}